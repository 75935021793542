<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>My Team</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">My Team</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row class="mb-1rem" >
                <b-col lg="5"  v-if="hasPermission('CAN_VIEW_ALL_TEAMS')">
                    <b-form-group
                        label="Reporting To"
                        label-cols-sm="3"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-size:1rem !important;"
                    >
                        <b-input-group size="md" >
                            <div class="col-md-9 ">
                                <EmployeeList :reporting_to="employeeReportingto" @emp_id="getEmpId" ref="employeeId"></EmployeeList>
                            </div>
                            <b-button
                                :disabled="!filter"
                                @click="onFiltered"
                                class="btn btn-primary col-md-3"
                                variant="primary"
                            >Search</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                 <b-col lg="5" >
                    <b-form-group
                        label=""
                        label-cols-sm="0"
                        label-align-sm="left"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-size:1rem !important;"
                    >
                        <b-input-group size="md" >
                            <div class="col-md-9 ">
                                <multiselect
                                    v-model="selectedEmployee"
                                    :options="employee"
                                    :multiple="false"
                                    placeholder="Employee Name or ID"
                                    :custom-label="customLabel"
                                    :searchable="true"
                                    :search-function="teamSearchFilter"
                                >
                                 <template v-slot:noResult>
                                    <span class="no-results">
                                        No Employee Found
                                    </span>
                                </template>
                                </multiselect>
                            </div>
                            <b-button
                                :disabled="!selectedEmployee"
                                @click="onTeamSearchFilter"
                                class="btn btn-primary col-md-3"
                                variant="primary"
                            >Search</b-button>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                responsive
            >
                <template v-slot:cell(employee)="row">
                    {{row.item.full_name}} [IES-{{row.item.id}}]
                </template>
                <template v-slot:cell(reporting_to)="row">
                    {{row.item.reporting_to_employee.reporting_to.full_name}} [IES-{{row.item.reporting_to_employee.reporting_to.id}}]
                </template>
                 <template v-slot:cell(actions)="row">
                 <router-link
                            v-if="hasPermission('CAN_VIEW_MY_TEAM')"
                            :to="({ name: 'team-attendance', query: { employee_id: row.item.id, year:year}})"
                            v-b-tooltip.hover title="Attendance"
                            class="circle ml-1"
                        >
                            <font-awesome-icon icon="calendar-alt" class="text-success"></font-awesome-icon>
                        </router-link>
                 </template>
            </b-table>

        </b-container>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
import EmployeeList from "@/components/EmployeeList.vue";
import Multiselect from 'vue-multiselect';
export default {
    components: {
        NotFound,
        EmployeeList,
        Multiselect
    },
    data() {
        return {
            api_error: "",
            urlBody: {
                query: "",
            },
            fields: [
                { key: "employee"},
                { key: "designation"},
                { key: "mobile_number"},
                { key: "reporting_to"},
                { key: "actions"},
            ],
            employeeReportingto:1,
            items: null,
            filter: "",
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            year: new Date().getFullYear(),
            employee: [],
            selectedEmployee: null,
        };
    },
    mounted() {
        this.access = this.hasAnyPermission(["CAN_VIEW_MY_TEAM", "CAN_VIEW_ALL_TEAMS"]);
        this.onPageLoad();
        this.employeeReportingto = (this.hasAnyPermission(['CAN_VIEW_ALL_TEAMS'])) ? 0 : 1;
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        onTeamSearchFilter() {
            this.items=[];
            this.items.push(this.selectedEmployee);
        },
        customLabel(option) {
            return `${option.full_name} (IES-${option.id})`;
        },
        teamSearchFilter(option, searchQuery) {
            const label = option.name.toLowerCase();
            const value = option.value.toLowerCase();
            return label.includes(searchQuery.toLowerCase()) || value.includes(searchQuery.toLowerCase());
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        getEmpId(id) {
            this.filter = id;
        },

        getDefaultFilters() {
            let api_params = {
                search: this.filter,
            };
            return this.deleteEmptyKeys(api_params);
        },

        onPageLoad() {
            const api_params =
                Object.keys(this.$route.query).length > 0
                    ? this.$route.query
                    : this.getDefaultFilters();
            this.setDefaultFilters();
            this.getMyTeam(api_params);
        },
        
        setDefaultFilters() {
            let api_params = this.deleteEmptyKeys(this.$route.query);
            this.filter = api_params.search
                ? api_params.search
                : this.filter;
        },

        onFiltered() {
            var query = {search :this.filter};
            this.getMyTeam(query);
        },

        getMyTeam(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = params;
            this.axios.get(this.$api.get_my_team, query).then(response => {
                this.items = response.data.data;
                this.employee=[];
                 this.items.forEach(employee => {
                    this.employee.push(employee);
                });
                if (this.hasPermission("CAN_VIEW_ALL_TEAMS") && params.search){
                    this.$refs.employeeId.getSelectedEmployee(params.search);
                }
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
    
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>
